.close-camera-icon {
  margin-top: 20px !important;
}

.close-camera-icon .ant-btn-icon {
  position: relative;
  top: -4px;
}

.check-out-icon {
  margin-top: 20px !important;
}

.check-out-icon .anticon-export {
  position: relative;
  top: -3px;
}

.attendee-card {
  display: flex;
  align-items: center;
}

.bg-status-default {
  background-color: #D9D9D9;
}

.bg-Booked,
.bg-booked {
  background-color: #2DAB00;
}

.bg-Cancel,
.bg-Canceled {
  background-color: #fcb92c;
}
.event-confirm-attendees-check-in {
  order: 2;
}

.attendee-table .ant-checkbox-disabled .ant-checkbox-inner {
  background: #81CA67;
  border-color: #fff;
}
.attendee-table .ant-checkbox-disabled .ant-checkbox-inner::after {

  border-color: #fff;
}

.sidebar-nav {
  padding: 0px 8px;
}
.sidebar-link, a.sidebar-link {
  border-radius: 10px;
  margin-bottom: 5px;
}

.sidebar-item.active .sidebar-link:hover, 
.sidebar-item.active>.sidebar-link,
.sidebar-item .sidebar-link:hover {
  background: #fff;
  color: #222e3c;
  border-radius: 10px;
  border-left: 3px solid #fff;
 padding: .625rem 1.625rem;
 transition: none;
}
.sidebar-item.active .sidebar-link:hover svg *, 
.sidebar-item.active>.sidebar-link svg *,
.sidebar-item .sidebar-link:hover svg * {
 fill: #222e3c;
}