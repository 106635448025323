:root {
  --dean-room: #7F56D9;
  --game-room: #0072F9;
  --board-room: #000000;
  --bull-room: #FF2F2F;
  --golf-room: #269100;
}

.h3.heading {
  font-size: 28px;
  font-weight: 600;
}

.h4.heading {
  font-size: 20px;
  font-weight: 600;
}


/* hook calendar */

.date-text {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.customCalendar .rbc-month-view {
  border: none
}

.customCalendar .rbc-header {
  border-bottom: none;
}

.customCalendar .rbc-month-view .rbc-header+.rbc-header {
  border-left: none
}

.customCalendar .rbc-row.rbc-month-header .rbc-header {
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 3px;
  color: #000000;
}

.customCalendar .rbc-row.rbc-time-header-cell {
  height: 55px;
}

.customCalendar .rbc-row.rbc-time-header-cell .rbc-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customCalendar .rbc-allday-cell {
  display: none;
}
.customCalendar .rbc-day-bg {border-top: 1px solid #F0F0F0;}
.customCalendar .rbc-day-bg+.rbc-day-bg {
  border-left: none;
  /* margin: 0 4px; */
  border-top: 1px solid #F0F0F0;
}

.customCalendar .rbc-toolbar button {
  border-radius: 10px;
}

.customCalendar .rbc-toolbar button:active,
.customCalendar .rbc-toolbar button.rbc-active {
  box-shadow: none;
  background-color: #E6F4FF;
  border-color: #adadad;
}

.toolbarCalendar .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #badefa;
  color: #000000;
  font-weight: bold;
  background: #E6F4FF;
}

.toolbarCalendar .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #badefa
}

.customCalendar .rbc-time-header-content {
  border-left: 1px solid #F0F0F0;
}

.customCalendar .rbc-time-content {
  border-top: 1px solid #f0f0f0;
}

.customCalendar .rbc-header+.rbc-header {
  border-left: 1px solid #F0F0F0;
}

.customCalendar .rbc-time-content>*+*>* {
  border-left: 1px solid #F0F0F0;
}

.customCalendar .rbc-timeslot-group {
  border-bottom: 1px solid #F0F0F0;
  min-height: 69px;
}

.customCalendar .rbc-time-view-resources .rbc-time-gutter,
.customCalendar .rbc-time-view-resources .rbc-time-header-gutter {
  border-right: 1px solid #F0F0F0;
}

.customCalendar .rbc-time-view {
  border: none;
}

.customCalendar .rbc-time-slot .rbc-label {
  font-size: 13px;
  font-weight: 500;
}

.customCalendar .rbc-day-bg.rbc-off-range-bg {
  background: #f0f2f4;
  /* margin: 0 1px; */
}

.customCalendar .rbc-date-cell {
  padding-top: 5px;
  font-size: 12px;
}

.customCalendar .rbc-month-row+.rbc-month-row {
  border-top: none
}

.customCalendar .rbc-today {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08) inset;
  border-radius: 6px;
}

/* end hook calendar */

.rsHd i {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 20px;
}

.eventBar {
  border-radius: 20px;
  color: #FFF;
  padding: 2px 6px;
  font-size: 12px;
  cursor: pointer;
}

.eventBar.holiday {
  background-color: #D9D9D9;
  color: #AAAAAA;
}

.timeBar {
  border-radius: 2px;
  border: 1px #FFF solid;
  background-color: #0072F9;
}

.timeList {
  transition: all 0.3s ease-in-out;
}

.timeList.time-slot .ant-modal-content {
  padding: 16px;
}

.timeList .event-title {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.timeList .ant-modal-content {
  padding: 0px;
}

.timeList .room-img-wrap {
  padding: 40px 20px 0px;
}

.item-detail-list-wrap {
  padding: 20px;
}

.item-detail-list-wrap .content-event-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 94%;
}
.item-detail-list-wrap .content-event-special-request {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.controlBox {
  position: absolute;
  top: 0;
  right: 0;
}

.modalMove {
  transform: translateX(410px);
}

.btnModal {
  padding: 4px 8px;
  font-size: 22px;
}

.bookingListItem {
  cursor: pointer;
  border-radius: 5px;
  padding: 2px 8px;
}

.bookingListItem:hover {
  background: #95e8d9;
}

.bookingListItem .booking-cancel {
  background: linear-gradient(to right, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 50%);
  background-size: 100% 1px;
  background-position: 0 50%;
  background-repeat: repeat-x;
  word-break: break-all;
}

.bookingListItem .organize-name {
  color: #5E5E5E !important;
    font-weight: 600;
 }

.cursor-pointer {
  cursor: pointer;
}

.listEquipment ul li.hasDot::before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background: #7C7C7C;
  display: inline-block;
}

.equipments-checkbox .ant-checkbox-wrapper{
   width: 46%;
   margin-bottom: 10px;
}

.room-item {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}

.btn-note {
  width: 127px;
}

.calendar-wrap {
  padding: 0 1.5rem 1.5rem;
}



.create-meeting-wrap .ant-radio-button-wrapper{
  border-color: #000;
}
.create-meeting-wrap .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #000;
}

.create-meeting-wrap .ant-radio-button-wrapper-checked {
  border-color: #000000 !important;
  font-weight: 600 !important;
  color: #000 !important;
  background-color: #E6F4FF !important;
}
.create-meeting-wrap .ant-radio-button-wrapper:first-child {
  border-color: #000;
}

.create-meeting-wrap .other-rooms-link {
  text-decoration: underline !important;
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.create-meeting-wrap .btn-time-slot {
  border: 1px solid #000;
  color: #000;
}

.create-meeting-wrap .text-red {
  color: #ff4d4f;
  font-family: SimSun, sans-serif;
}

.create-meeting-wrap .ant-form-item-no-colon {
  display: flex;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 1.1 !important;
}
.create-meeting-wrap .ant-form-item-no-colon::before {
  order: 2;
}
.create-meeting-wrap .ant-form-item-no-colon::after {
  content: '';
  margin: 0 !important;
}
.create-meeting-wrap .btn-manage-attendees {
  width: 100%;
  height: 52px;
  font-weight: 600;
  font-size: 16px;
}
.create-meeting-wrap .ant-input-disabled  {
  color: #000000 !important;
}
.create-meeting-wrap .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #81CA67;
  border-color: #81CA67;
}
.time-slot-calendar .rbc-event {
  background-color: #CACACA;
  color: #6D6D6F;
  border-color: #CACACA !important;
}
.time-slot-calendar .rbc-event-label {
  font-size: 12px;
  order: 2;
}
.time-slot-calendar .rbc-event-content {
  font-size: 12px;
  flex: none;
}
.customCalendar.time-slot-calendar  .rbc-timeslot-group {
  border-bottom: 0;
}

.customCalendar.time-slot-calendar .rbc-time-content>*+*>* {
 border-left: 0px;
}
.customCalendar.time-slot-calendar .rbc-day-slot .rbc-event {
  padding: 12px;
  margin-left: 5px;
}

.meeting-room-card-title {
  font-weight: 400;
}
.blank-img {
  background-color: #D9D9D9;
  width: 100%;
  height: 313px;
}
.room-detail-title {
  font-weight: 600;
  font-size: 22px;
}