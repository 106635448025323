.information-wrap {
  border: 1px solid #D8D8D8;
  padding: 20px;
  border-radius: 6px;
  height: 100%;
}

.information-wrap .title {
  font-size: 16px;
}

.total-box {
  box-shadow: 0px 0px 4px 0px #3CBC0F;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 15px;
}
.total-box .title {
  font-size: 13px;
  font-weight: 600;
}
.total-box .number {
  font-size: 40px;
  text-align: center;
  font-weight: 800;
  color: #919191;
  margin: 20px 0;
}
.total-box .number span {
  color: #3CBC0F;
}
.total-sub-box {
  border-radius: 8px;
  padding: 12px;
}

.total-sub-box .title {
  font-size: 11px;
  color: #373737;
  font-weight: 600;
}
.total-sub-box .number {
  font-size: 36px;
  text-align: center;
  font-weight: 800;
  color: #4A4A4A;
  margin: 20px 0;
}
.total-sub-box.total-sub-sm-box .number{
  margin: 10px 0;
  font-size: 30px;
}

.total-sub-box .number span {
  color: #3CBC0F;
}
.total-sub-box.box-purple {
  box-shadow: 0px 2px 4px 0px #C5B0FF9C;
}
.total-sub-box.box-blue {
  box-shadow: 0px 2px 4px 0px #73B2FF9E;
}

.attendee-table .attendee-canceled .ant-checkbox-disabled .ant-checkbox-inner {
  background: #C4C4C4 !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.total-amount-graph {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.attendance-total {
  font-size: 32px;
  font-weight: 700;
  color: #3CBC0F;   
}

.booking-total {
  font-size: 32px;
  font-weight: 700;
  color: #378CE7;
}

@media (max-width: 1617px) {
  .attendance-total,
  .booking-total {
    font-size: 20px;
  }
  .total-amount-graph {
    max-width: 50px;
    padding-top: 5px;
  }
  .total-amount-graph p {
    margin: 0;
  }
  .attendance-total,
  .booking-total {
    font-size: 20px !important;
  }
}

@media (max-width: 1200px) {
  .total-amount-graph p:not(.attendance-total,.booking-total) {
    font-size: 10px !important;
  }
}