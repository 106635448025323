body{ font-size: 1rem; line-height: normal;}
.text-xs{ font-size: 0.75rem; line-height: 1rem;}
.text-sm{ font-size: 0.875rem; line-height: 1.25rem;}
.text-base{ font-size: 1rem; line-height: 1.5rem;}
.text-lg{ font-size: 1.125rem; line-height: 1.75rem; }
.text-xl{ font-size: 1.25rem; line-height: 1.75rem; }
.text-2xl{ font-size: 1.5rem; line-height: 2rem; }
.text-3xl{ font-size: 1.875rem; line-height: 2.25rem; }
.text-4xl{ font-size: 2.25rem; line-height: 2.5rem; }

.rounded-md{ border-radius: .375rem;}
.rounded-lg{ border-radius: .5rem;}
.rounded-xl{ border-radius: .6rem;}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.badge-verified .ant-badge-status-text {
  color: #4ab116 !important;
  /* replace with your preferred color */
}

.badge-waiting .ant-badge-status-text {
  color: rgb(180, 180, 180) !important;
  /* replace with your preferred color */
}

.sidebar-icon-image {
  border-radius: 10%;
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.line-text {
  display: flex;
  align-items: center;
  text-align: center;
  color: #aaa;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
}

.line-text::before,
.line-text::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e5e5e5;
}

.line-text::before {
  margin-right: 0.25em;
}

.line-text::after {
  margin-left: 0.25em;
}

.button-group {
  display: flex;
}

.custom-button {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  /* Adjust margin as needed */
}

.custom-button {
  display: flex;
  align-items: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-button-container {
  margin-left: auto;
}

.create-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.ant-upload-list-item {
  height: 100px !important;
  /* Set to your preferred height */
  /* other styles as needed */
}

.image-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.square-image {
  object-fit: cover;
  object-position: top;
}

.canvas-image {
  object-fit: cover;
  object-position: top;
}

.live-preview .title {
  font-family: 'Noto Sans', sans-serif;
  font-size: 26px;
  font-weight: 600;
}

.live-preview .created-by {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.live-preview .last-updated,
.live-preview .updated-time {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: #787a7d;
  font-weight: 400;
}

.live-preview .description-header,
.live-preview .requirement-header {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.live-preview .event-type {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.live-preview .attendees,
.live-preview .paid-amount {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.live-preview .attendees-amount {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.height-36 {
  height: 36px;
}

.height-24 {
  height: 24px;
}

.height-32 {
  height: 32px;
}

.error-message {
  color: red;
}

.attendees img {
  object-fit: cover !important; /* หรือ contain, fill, none, scale-down ตามความต้องการ */
}

.ml-5 {
  margin-left: 5px;
}

.btn.float-end span svg {
  vertical-align: baseline !important;
}

.button-group .btn span,
.button-group .btn span svg {
  vertical-align: text-bottom !important;
}

/* hook bootstrap */
.btn:hover {
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: var(--bs-btn-hover-border-color) !important;
  color: var(--bs-btn-hover-color) !important;
}


/* sweet */
.swal2-actions{ gap: 14px;}
.swal2-title{font-size: 1.4em;}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  border-radius: 32px !important;
  padding: 7px 16px;
  min-width: 90px;
  background-color: #4A4A4A !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus{box-shadow: none !important}
/* div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus{box-shadow: 0 0 0 3px rgb(102 224 192 / 50%) !important} */
div:where(.swal2-container) .swal2-html-container{margin: 0.6em 1.6em .4em !important;}
.btnSweetSuccess{border-radius: 32px; padding: 7px 16px; color: #09ac6e; background-color: #FFF; border: 1px solid #09ac6e;}
.btnSweetSuccess:hover{color: #1ac382; border: 1px solid #1ac382;}

.btnSweetDanger{border-radius: 32px; padding: 7px 16px; color: #cd1c16; background-color: #FFF; border: 1px solid #cd1c16;}
.btnSweetDanger:hover{ color: #e5413b; border: 1px solid #e5413b;}

.btnSweetBordered{border-radius: 32px; padding: 7px 16px; color: #000000; background-color: #FFF; border: 1px solid #000000;}
.btnSweetBordered:hover{ color: #5e5e5e; border: 1px solid #5e5e5e;}

.btnSweetPrimary{border-radius: 32px; padding: 7px 16px; color: #FFFFFF; background-color: #0072F9; border: 1px solid #0072F9;}
.btnSweetPrimary:hover{ color: #FFFFFF; border: 1px solid #0072F9;}

.btnSweetBlack{border-radius: 32px; padding: 7px 16px; color: #FFFFFF; background-color: #000000; border: 1px solid #000000;}
.btnSweetBlack:hover{ color: #FFFFFF; border: 1px solid #000000;}
.btnSweetGray{border-radius: 32px; padding: 7px 16px; color: #FFFFFF; background-color: #4A4A4A; border: 1px solid #4A4A4A;}
.btnSweetGray:hover{ color: #FFFFFF; border: 1px solid #4A4A4A;}
